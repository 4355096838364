/**
 * Go to pageTop from common.js
 */
$(function(){
  $(".pageTop span").on("click", function() {
    $("body, html").animate({scrollTop:0}, 'slow');
  });
});

/**
 * More Button
 */
document.addEventListener(
  "DOMContentLoaded",
  function() {
    const jsMoreBtn = document.getElementById("jsMore-btn");
    const jsMoreBtnClose = document.getElementById("jsMore-btn-close");
    const jsMoreArea = document.getElementById("jsMore-area");

    if (jsMoreBtn) {
      jsMoreBtn.addEventListener(
        "click",
        function() {
          this.classList.toggle("jsHide");
          jsMoreArea.classList.toggle("jsHide");
          jsMoreBtnClose.classList.toggle("jsHide");
        },
        false
      );

      jsMoreBtnClose.addEventListener(
        "click",
        function() {
          this.classList.toggle("jsHide");
          jsMoreArea.classList.toggle("jsHide");
          jsMoreBtn.classList.toggle("jsHide");
        },
        false
      );

      // Hide buttons on desktop view.
      if (window.innerWidth >= 768) {
        jsMoreBtn.classList.add("jsHide");
        jsMoreArea.classList.remove("jsHide");
        jsMoreBtnClose.classList.add("jsHide");
      }
    }
  },
  false
);

/**
 * Slick
 */
$(function() {
  if ($(".jsSlideCard").length) {
    // If there are not enough slides, cloning slides into it.
    // (slidesToShow: 4)
    while ($(".jsSlideCard").children().length < 4) {
      $(".jsSlideCard").append(
        $(".jsSlideCard")
          .children()
          .clone()
      );
    }

    $(".jsSlideCard").slick({
      autoplay: true,
      autoplaySpeed: 4000,
      arrows: false,
      centerMode: true,
      responsive: [
        {
          breakpoint: 99999,
          settings: {
            centerPadding: "120px",
            slidesToShow: 4
          }
        },
        {
          breakpoint: 1200,
          settings: {
            centerPadding: "120px",
            slidesToShow: 3
          }
        },
        {
          breakpoint: 768,
          settings: {
            centerPadding: "60px",
            slidesToShow: 1
          }
        }
      ]
    });
  }

  if ($(".jsSlide").length) {
    $(".jsSlide").slick({
      autoplay: true,
      autoplaySpeed: 4000,
      dots: true,
      adaptiveHeight: true,
      slidesToShow: 1,
      responsive: [
        {
          breakpoint: 99999,
          settings: {
            arrows: false,
            customPaging(slick, index) {
              // スライダーのインデックス番号に対応した画像のsrcを取得
              const targetImage = slick.$slides
                .eq(index)
                .find("img")
                .attr("src");
              // slick-dots > li の中に上記で取得した画像を設定
              return `<img src=${targetImage} />`;
            }
          }
        },
        {
          breakpoint: 768,
          settings: {
            arrows: true,
            prevArrow:
              '<span class="slide_prev-arrow"><img src="/images/icon_arrow-left.svg"></span>',
            nextArrow:
              '<span class="slide_next-arrow"><img src="/images/icon_arrow-right.svg"></span>'
          }
        }
      ]
    });
  }
});

/**
 * Modal
 */

$(function() {
  $(".searchButton").magnificPopup({
    items: {
      src: "#modal-1"
    },
    type: "inline",
    preloader: false,
    fixedContentPos: true,
    closeMarkup:
      '<button type="button" class="mfp-close modal__close" aria-label="Close Modal"></button>'
  });
});

$(function() {
  $(".plusButton").magnificPopup({
    items: {
      src: "#modal-2"
    },
    type: "inline",
    preloader: false,
    fixedContentPos: true,
    closeMarkup:
      '<button type="button" class="mfp-close modal__close" aria-label="Close Modal"></button>'
  });
});

$(function() {
  $(".js-modal-close").on("click", function(e) {
    e.preventDefault();
    $.magnificPopup.close();
  });
});

/**
 * Search map list animation
 */
$(function() {
  const jsSearchMapBtn = $(".js-search-map__btn");
  const jsSearchMapBtnList = $(".js-search-map__btn--list");
  const jsSearchMapList = $(".js-search-map__list");

  jsSearchMapBtn.on("click", function() {
    jsSearchMapList.fadeOut("fast", function() {
      jsSearchMapBtnList.css("display", "block");
      jsSearchMapBtn.css("display", "none");
    });
  });

  jsSearchMapBtnList.on("click", function() {
    jsSearchMapList.fadeIn("fast", function() {
      jsSearchMapBtnList.css("display", "none");
      jsSearchMapBtn.css("display", "block");
    });
  });
});

/**
 * Fit the window
 *
 * Layout the DIV box to fit the window for Smartphone view on search-result-map.
 */
$(function() {
  const heightSize = $(window).height();
  $(".js-map").height(heightSize - 149); // Search Map
});
